:root {
    /* Theme CSS variables will be set dynamically by styled-components */
    --background: #E6EDF1;
    --accent: #F4F6FA;
    --text: #F4F6FA;
    --secondary-text: #F4F6FA;
    --border: #000000;
    --layout-border: #0A1A25;
    --card: #03090b;
    --card-border: #FFFFFF;
    --input: #FFFFFF;
    --input-border: #000000;
    --button: #4A5878;
    --button-text: #52525266;
    --button-hover: #E4E4E7;
    --button-disabled: #eeeeee;
    --button-disabled-text: #FFFFFF;
    --tab: #E4E4E7;
    --tab-text: #FFFFFF;
    --tab-active: #E4E4E7;
    --tab-text-active: #4A5878;
    --tab-hover: #FFFFFF;
    --tab-border: #3A4868;
    --link: #E4E4E7;
    --link-hover: #9CA3AF;
    --success: transparent;
    --error: #555555;
    --warning: #F0F4FF;
    --info: #4361EE;
    --shadow: #5B3DE855;
    --copy-limit-bg: #F0F4FF;
    --copy-limit-bg-hover: #F0F4FF;
    --copy-limit-text: #666666;

    /* Add transition for theme switching */
    --transition-speed: 0.3s;
    --transition-timing: ease;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
    color: var(--text);
    transition: background-color var(--transition-speed) var(--transition-timing),
    color var(--transition-speed) var(--transition-timing);
}

* {
    box-sizing: border-box;
}

#root {
    height: 100vh;
}

/* Apply transitions to common elements */
a, button, input, select, textarea,
.card, .panel, .dropdown, .modal,
[class*="wrapper"], [class*="container"], [class*="content"] {
    transition: background-color var(--transition-speed) var(--transition-timing),
    color var(--transition-speed) var(--transition-timing),
    border-color var(--transition-speed) var(--transition-timing),
    box-shadow var(--transition-speed) var(--transition-timing);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.o--Widget--popupBg {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        padding: 20px !important;
    }
}